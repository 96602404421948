import { TClientConfig } from '../hexagon/interfaces';

const config: TClientConfig = {
    lang: 'fr',
    locale: 'fr-FR',
    currency: 'EUR',
    identifier: 'spoticar',
    name: 'Spoticar',
    privateSellLink:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?make=[make]&year=[year]&month=[month]&utm_source=autobiz&utm_medium=iframe&utm_campaign=tasacion',
    noDealersAdsLinkValuation:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?utm_source=autobiz&utm_medium=appointment-page&utm_campaign=tasacion',
    noDealersAdsLinkSale:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?utm_source=autobiz&utm_medium=appointment-page&utm_campaign=venta-directa',
    displayRegistrationOption: false,

    emailConfirmation: true,
    nameRegex: "^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ ,.']*$",
    phoneRegex: '^(0|.33|0033)[1-9]([-.]?[0-9]{2}){4}$',
    phoneRadRegex: '^(06|07)([-.]?[0-9]{2}){4}$',
    zipCodeRegex: '^[0-9]{5}$',
    registrationRegex:
        '^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$|^[0-9]{1,4}[a-zA-Z]{1,3}[0-9]{2,3}$|^[0-9]{1,4}[a-zA-Z]{1,3}2[a-bA-B]$',
};
export default config;
